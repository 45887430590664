@import '../../core/styles/colors.scss';

.btn{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  margin-left: 20px;
  &.btn-secondary{
    background: transparent;
    border-color:$color-primary ;
    color:$color-primary;
  }
  &.btn-primary{
    background: $color-primary;
    border-color:$color-primary ;
  }
}
.mr-pages{
    background: $white;
    width: 90%;
    height: 100%;
    color:$black;
    padding: 30px  30px 60px 30px;
    &__body{
      &__box{
        &__buttons {
          display: flex;
          justify-content: start;
          & button {
            margin-right: 30px;
          }
        }

      }
    }

}

.c-filteruser{
  width: 280px;
  position: relative;
  &__head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__title{
      font-size: 22px;
    }
    &__close_button_modal{
      font-size: 22px;
      cursor: pointer;
    }
  }

  &__form{
    width: 100%;
   &__label{
      font-size: 14px;
      margin-top: 20px;
      display: block;
    }
    &__checkusertype{
      margin-top: 10px;
      display: grid;
      grid-gap: 0.5rem;
    }
    &__buttonsAccions{
      margin-top: 35px;
      display: flex;
      justify-content: space-between;
      width: 240px;
    }
  }

}

.mr-filteruser-radio-button{
  margin: 8px 0;
}

.mr-mat-form-field-input{
  width: 272px;
}

.mr-mat-form-field-select {
  .mat-select-placeholder {
    color: $text-dark;
    position: relative;
    top: -3px;
  }
  .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
    color: $text-dark;
  }

  .mat-form-field.mat-focused .mat-form-field-label {
    color: $text-dark;
  }
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 3px 0 3px 0;
  }
  .mat-form-field-appearance-outline .mat-form-field-label {
    top: 15px;
    margin-top: -0.25em;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-start,
  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border: none !important;
    background-color: $primary-white;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-gap {
    border: none !important;
    background-color: $primary-white;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-radius: 6px 0 0 6px;
    border-right-style: none;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-radius: 0 6px 6px 0;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-start {
    width: 15px !important;
  }
  mat-form-field {
    width: 280px;
    font-size: 18px;
    font-family: "Inter" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
  .mat-form-field-appearance-outline {
    border: none !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0 15px;
    margin-top: -0.25em;
    position: relative;
  }
}

.select-multiple{
  width: 100%;
  font: inherit;
    padding: 0px;
    height: 43px;
}
