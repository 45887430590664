@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,700;0,800;0,900;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.mr-inter-text-very-small{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
}

.mr-inter-text-min-name{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.mr-inter-text-min{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.mr-inter-text-med{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}
.mr-inter-text-max{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
}
.mr-inter-text-regular-min-sub-titule{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.mr-inter-text-regular-normal-sub-titule{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

.mr-inter-text-regular-normal-sub-titule-18-semiBold{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
}

.mr-inter-text-normal{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 24px;
}

.mr-inter-text-normal-bold{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
}

.mr-inter-text-regular-normal-titule{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
}
.mr-inter-text-regular-semibold-titule{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}

.mr-inter-text-regular-bold-titule{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 130%;
}

.mr-roboto-text-regular-bold-sub-titule{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 130%;
}

.mr-roboto-regular-font{
  font-family: 'Roboto';
  font-weight: 400;
}

.mr-roboto-bold-font{
  font-family: 'Roboto';
  font-weight: 800;
}

.au-Inter-regular-font{
  font-family: 'Inter';
  font-weight: 400;
}

.au-raleway-light-font{
  font-family: 'Inter';
  font-weight: 300;
}

.au-Inter-regular-font{
  font-family: 'Inter';
  font-weight: 400;
}

.au-Inter-medium-font{
  font-family: 'Inter';
  font-weight: 500;
}

.au-Inter-semi-bold-font{
  font-family: 'Inter';
  font-weight: 600;
}

.au-Inter-bold-font{
  font-family: 'Inter';
  font-weight: 700;
}

.au-Inter-extra-bold-font{
  font-family: 'Inter';
  font-weight: 800;
}

.au-Inter-italic{
  font-family: 'Inter';
  font-style: italic;
  font-weight: normal;
}

.au-Inter-normal {
  font-family: 'Inter';
  font-style: normal;
}

.mr-size-14 {
  font-size: 14px;
}

.mr-size-15 {
  font-size: 15px;
}

.mr-size-16 {
  font-size: 16px;
}

.mr-size-18 {
  font-size: 18px;
}

.mr-size-19 {
  font-size: 19px;
}

.mr-size-20 {
  font-size: 20px;
}

.mr-size-21 {
  font-size: 21px;
}

.mr-size-22 {
  font-size: 22px;
}

.mr-size-24 {
  font-size: 24px;
}

.mr-size-28 {
  font-size: 28px;
}

.mr-size-32 {
  font-size: 32px;
}
.mr-aling-justify{
  text-align: justify;
}
