$color-primary:#00529b;
$white:#ffff;
$red:#D32222;
$black:#000;
$gris1:#b5b5b5;

// Primarios
$primary-white: #ffffff;
$primary-light-gray: #f3f3f3;
$primary-blue: #416ca7;
$primary-lith-blue: #6092f4;
$primary-secon-ligth-blue: #7cb1d2;
$primary-invalid: #f47560;
$percentages-blue: #4a3aff;
$percentages-darkblue: #082044;
$pink-primary: #f9dedc;
$blue-cian: #cedbec;
$text-alert-red: #b3261e;
$backgroun-orange: #ff9c56;

// Secondary
$secondar-grey: #d3d3d3;
$secondar-grey-ADADAD: #adadad;
$secondar-grey-F8F: #f8f8ff;
$secondar-green: #43a047;
$secondar-white-grey: #eef8ff;

// Texo color
$text-gray: #7e7e7e;
$text-gray-525: #525252;
$text-dark-blue: #43576a;
$text-dark: #353e46;
$primary-text-blue: #19415b;
$text-red: #dc362e;

$blue-grey: #f2f6ff;
$red-medium: #e46962;
$yellow-medium: #ffb74d;

.color-red{
  color: $red;
}
.color-blue{
  color:$primary-blue;
}

.color-black{
  color: $black;
}
.color-white{
  color: $white;
}

.background-blue{
  background: $color-primary;
}

.background-white{
  background-color: $white;
}

.background-gris{
  background-color: $gris1;
}

.dh-icon-dark {
  svg {
    g {
      fill: $text-dark;
      path {
        fill: $text-dark;
      }
    }
    path {
      fill: $text-dark;
    }
  }
}

.dh-icon-white {
  svg {
    g {
      fill: $primary-white !important;
      path {
        fill: $primary-white !important;
      }
    }
    path {
      fill: $primary-white !important;
    }
  }
}

.dh-icon-general {
  svg {
    g {
      fill: #858586;
      path {
        fill: #858586;
      }
    }
    path {
      fill: #858586;
    }
  }
}
